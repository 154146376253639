import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Content from "../components/content"
import Intro from "../components/intro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

import * as styles from "./404.module.scss"

const NotFound = ({ data }) => {
  const yml = data.allDataYaml.edges[0].node.error
  return (
    <Layout>
      <Content>
        <Intro title={yml.title} description={yml.description} />
        <Link className={styles.link} to="/">
          <FontAwesomeIcon className={styles.icon} icon={faLongArrowAltLeft} />
          {yml.cta}
        </Link>
      </Content>
    </Layout>
  )
}

export default NotFound

export const query = graphql`
  query {
    allDataYaml(sort: {fields: error___title}) {
      edges {
        node {
          error {
            cta
            description
            title
          }
        }
      }
    }
  }
`
